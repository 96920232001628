<ion-app>
  <ion-menu
    #menu
    type="overlay"
    contentId="my-content">
    <ion-header>
      <ion-toolbar
        class="menu-logo-wraper"
        color="primary">
        <ion-menu-toggle>
          <ion-grid>
            <ion-row>
              <ion-col
                size="4"
                class="avatar-wraper"
                [routerLink]="['/perfil']">
                <ion-avatar
                  class="ion-item-center menu-user-img-wraper"
                  class="avatar-icon">
                  <ion-img
                    *ngIf="user && user.profileImage"
                    [src]="user.profileImage"
                    class="img-profile"></ion-img>
                  <ion-icon
                    name="person-circle-outline"
                    *ngIf="!user || (user && !user.profileImage)"
                    class="avatar-icon"></ion-icon>
                  <h6
                    *ngIf="user && user.premium"
                    class="tw-mt-4 tw-mb-2.5 secondary-text">
                    🏆 Premium
                  </h6>
                </ion-avatar>
              </ion-col>

              <ion-col
                size="8"
                class="tw-cursor-pointer"
                *ngIf="user"
                [routerLink]="['/perfil']">
                <h4 class="tw-mt-2 tw-font-bold">
                  {{ user.name }} {{ user.surname }}
                </h4>
                <h6>
                  <b>{{ user.email }}</b>
                </h6>
                <h6 class="tw-mt-4 tw-mb-2.5">Editar perfil</h6>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-menu-toggle>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <!-- Pages -->
        <div *ngFor="let page of appPages">
          <ion-menu-toggle
            auto-hide="false"
            *ngIf="page.show()">
            <ion-item
              [routerDirection]="'root'"
              [routerLink]="''"
              (click)="page.click()"
              class="pointer">
              <ion-icon
                slot="start"
                [name]="page.icon"
                color="primary"></ion-icon>
              <ion-label class="primary-text">
                {{ page.title }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </div>
        <!-- Instalar App -->
        <ion-menu-toggle
          auto-hide="false"
          *ngIf="showInstall">
          <ion-item
            [routerDirection]="'root'"
            [routerLink]="''"
            (click)="installPWA()">
            <ion-icon
              slot="start"
              name="open-outline"
              color="primary"></ion-icon>
            <ion-label class="primary-text"> Instalar App </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <!-- Activar notificaciones -->
        <ion-menu-toggle
          auto-hide="false"
          *ngIf="showNotifications">
          <ion-item
            [routerDirection]="'root'"
            [routerLink]="''"
            (click)="activateNotifications()"
            class="pointer">
            <ion-icon
              slot="start"
              name="chatbubbles-outline"
              color="primary"></ion-icon>
            <ion-label class="primary-text"> Activar mensajes </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <!-- administrador -->
        <ion-menu-toggle *ngIf="isAdmin()">
          <ion-item
            button="true"
            class="side-menu-item"
            [routerLink]="['/admin']">
            <ion-icon
              slot="start"
              name="pizza-outline"
              color="primary"></ion-icon>
            <ion-label class="primary-text"> Administrador </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <!-- panel de ventas -->
        <ion-menu-toggle *ngIf="isSeller()">
          <ion-item
            button="true"
            class="side-menu-item"
            [routerLink]="['/sales']">
            <ion-icon
              slot="start"
              name="cash-outline"
              color="primary"></ion-icon>
            <ion-label class="primary-text"> Mis Ventas </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
    <!-- footer -->
    <ion-footer>
      <ion-toolbar>
        <ion-row>
          <!-- cambio de rol -->
          <ion-col
            size="12"
            *ngIf="user && user.onboardingType">
            <ion-menu-toggle auto-hide="false">
              <ion-item
                class="pointer"
                (click)="changeUserRole()">
                <ion-label class="primary-text tw-text-sm">
                  Cambiar a rol
                  {{
                    user.onboardingType === 'company' ? 'persona' : 'empresa'
                  }}
                </ion-label>
                <ion-icon
                  slot="end"
                  name="sync-circle-outline"
                  color="primary"
                  class="tw-text-xl"></ion-icon>
              </ion-item>
            </ion-menu-toggle>
          </ion-col>
          <!-- T&C -->
          <ion-col
            size="12"
            class="ion-text-center">
            <ion-menu-toggle auto-hide="false">
              <h4 class="tw-my-2">
                <a
                  class="primary-text pointer"
                  [routerLink]="'/servicio-al-cliente'">
                  <b>Servicio al Cliente</b>
                </a>
              </h4>
            </ion-menu-toggle>
            <h5 class="tw-my-2">
              <a
                class="primary-text pointer"
                (click)="openLink('politics', 'viewLegalPolitics')">
                Políticas de privacidad
              </a>
            </h5>
            <h5 class="tw-my-2">
              <a
                class="primary-text pointer"
                (click)="openLink('terms', 'viewLegalTerms')">
                Términos y condiciones
              </a>
            </h5>
            <ion-menu-toggle>
              <h5 class="tw-my-2">
                <a
                  class="danger-text pointer"
                  (click)="helperService.logout()">
                  Cerrar Sesión
                </a>
              </h5>
            </ion-menu-toggle>
            <h6 class="ion-text-center primary-text">{{ appVersion }}</h6>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-footer>
  </ion-menu>
  <ion-router-outlet id="my-content"></ion-router-outlet>
</ion-app>
